import React from 'react';
import style from '../assets/css/Multipliers.module.css';

import Multiplier from "./Multiplier";

const multipliers = [1, 5, 10, 25];

function Multipliers(props) {
    const changeMultiplier = (value) => {
        props.setMultiplier(value)
        try {
        window.umami.track('Upgrade multiplier button [' + value + ']');
        } catch (e ) {}
    };

    return (
        <div className={style.container}>
            {multipliers.map((count, i) => {
                if (count === props.multiplier) {
                    return <Multiplier text={`x${count}`} key={i} selected/>
                }
                return <Multiplier text={`x${count}`} key={i} count={count} changeMultiplier={changeMultiplier}/>
            })}
        </div>
    );
}


export default Multipliers;