import Topbar from './components/Topbar';
import Footer from './components/Footer';
import Container from "./components/Container";
import Upgrades from "./components/Upgrades";
import MobileFooter from './components/mobile/Footer';

import './i18n';
import {useEffect, useRef, useState} from "react";
import { useMediaQuery } from 'react-responsive'

// eslint-disable-next-line no-unused-vars
import style from './assets/css/Styles.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {decode as base64_decode} from "base-64";



function loadData(name, defuault) {
    let savedData = null;
    savedData = localStorage.getItem("data");

    if (!savedData) {
        return defuault;
    }

    let initialValue = defuault;

    try {
        initialValue = parseInt(JSON.parse(savedData)[name]);
    } catch (e) {
        console.log("Bad data: \"" + savedData + "\" fixing to default value: \"" + defuault + "\"");
    }


    return initialValue || defuault;
}

function App() {
    const [clicks, setClicks] = useState(loadData("clicks", 0));
    const [clickMultiplier, setClickMultiplier] = useState(loadData("clickMultiplier", 1));
    const [autoClicker, setAutoClicker] = useState(loadData("autoClicker", 1));
    const [autoClickerCooldown, setAutoClickerCooldown] = useState(loadData("autoClickerCooldown", 1));

    const [loop, setLoop] = useState(true);

    const [multiplier, setMultiplier] = useState(1);
    const [upgradesVisible, setUpgradesVisible] = useState(false)

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1100px)'})

    const { t, i18n } = useTranslation();

    useEffect(() => {
        let data = {"clicks": clicks, "clickMultiplier": clickMultiplier,
            "autoClicker": autoClicker,  "autoClickerCooldown": autoClickerCooldown};

        if (loop) {
            localStorage.setItem("data", JSON.stringify(data));
        }

        const intervalId = setInterval(() => {
            if (loop) {
                setClicks(clicks+(autoClicker-1))
            }
        }, 1000/autoClickerCooldown);

        return () => {
            clearInterval(intervalId);
        };
    }, [clicks, autoClicker, autoClickerCooldown, clickMultiplier]);

    if (window.matchMedia('(display-mode: standalone)').matches) {
        document.querySelector("html").style.touchAction = 'none';
    }

    const { data } = useParams();
    const navigate = useNavigate();
    const hasConfirmed = useRef(false);

    useEffect(() => {
        if (data && !hasConfirmed.current) {
            hasConfirmed.current = true;
            setLoop(false);

            setTimeout(function () {
                navigate(`/`);
                let decodedData = null
                try {
                    decodedData = base64_decode(data)
                } catch (e) {
                    console.log(e);
                    window.location.reload();
                }

                let parsedData = null
                try {
                    parsedData = JSON.parse(decodedData)
                } catch (e) {
                    console.log(e);
                    window.location.reload();
                }


                let text = `${t('Do you want import data?')} \n
                ${t('Clicks - count', { count: parsedData["clicks"]}).replace(/-/g, ":")}
                ${t('Click Multiplier - count', { count: parsedData["clickMultiplier"]}).replace(/-/g, ":")}
                ${t('Auto Clicker - count', { count: parsedData["autoClicker"]}).replace(/-/g, ":")}
                ${t('Auto Clicker Cooldown - count', { count: Math.floor((1/parseInt(parsedData["autoClickerCooldown"]))*1000)/1000 + "s (" + parsedData["autoClickerCooldown"]}).replace(/-/g, ":") + ")"}`;

                if (parsedData["timestamp"]) {
                    let timestamp = parseInt(parsedData["timestamp"])
                    const date = new Date(timestamp);
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();

                    const formattedDate = `${hours}:${minutes} - ${day}.${month}.${year}`;
                    text = text + "\n\n" + t('Created - date', { date: formattedDate});
                }

                // eslint-disable-next-line no-restricted-globals
                if (confirm(text)) {
                    try {
                        localStorage.setItem("data", decodedData);
                    } catch (e) {
                        localStorage.setItem("data", null);
                    }

                    setClicks(loadData("clicks", 0))
                    setClickMultiplier(loadData("clickMultiplier", 1))
                    setAutoClicker(loadData("autoClicker", 1))
                    setAutoClickerCooldown(loadData("autoClickerCooldown", 1))

                    setTimeout(function () {
                        setLoop(true);
                    }, 300)
                } else {
                    setLoop(true);
                }

                setClicks(loadData("clicks", 0))
                setClickMultiplier(loadData("clickMultiplier", 1))
                setAutoClicker(loadData("autoClicker", 1))
                setAutoClickerCooldown(loadData("autoClickerCooldown", 1))
            }, 500);


            // try {
            //     localStorage.setItem("data", base64_decode(data));
            // } catch (e) {
            //     localStorage.setItem("data", null);
            // }
            //
            // setLoop(true);
            // navigate('/', { replace: true });
            // window.location.reload();
        }
    }, [data, navigate]);

    if (!isDesktopOrLaptop) {
        return (
            <div className="App">
                <Topbar setLoop={setLoop}/>

                {!upgradesVisible && (
                    <Container clicks={clicks} setClicks={setClicks}
                               clickMultiplier={clickMultiplier}/>
                )}

                {upgradesVisible && (
                    <Upgrades multiplier={multiplier} setMultiplier={setMultiplier} clicks={clicks} setClicks={setClicks}
                              clickMultiplier={clickMultiplier} setClickMultiplier={setClickMultiplier}
                              autoClicker={autoClicker} setAutoClicker={setAutoClicker}
                              autoClickerCooldown={autoClickerCooldown} setAutoClickerCooldown={setAutoClickerCooldown} mobile={true}/>
                )}

                <MobileFooter upgradesVisible={upgradesVisible} setUpgradesVisible={setUpgradesVisible}
                              setClicks={setClicks} setClickMultiplier={setClickMultiplier}
                              setAutoClicker={setAutoClicker} setAutoClickerCooldown={setAutoClickerCooldown}
                              clicks={clicks}/>
            </div>
        )
    }

    return (
        <div className="App">
            <Topbar setLoop={setLoop}/>

            <Container clicks={clicks} setClicks={setClicks}
                       clickMultiplier={clickMultiplier}/>
            <Upgrades multiplier={multiplier} setMultiplier={setMultiplier} clicks={clicks} setClicks={setClicks}
                      clickMultiplier={clickMultiplier} setClickMultiplier={setClickMultiplier}
                      autoClicker={autoClicker} setAutoClicker={setAutoClicker}
                      autoClickerCooldown={autoClickerCooldown} setAutoClickerCooldown={setAutoClickerCooldown}/>


            <Footer setClicks={setClicks} setClickMultiplier={setClickMultiplier}
                    setAutoClicker={setAutoClicker} setAutoClickerCooldown={setAutoClickerCooldown}
                    clicks={clicks} setLoop={setLoop}/>
        </div>
    );
}

export default App;
