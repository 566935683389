import React, {useEffect} from 'react';
import Logo from './Logo';
import style from '../assets/css/Topbar.module.css';

import {decode as base64_decode, encode as base64_encode} from 'base-64';
// import {useNavigate, useParams} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";



import Icon from "./Icon";

import fileExport from "../assets/svg/file-export.svg";
import fileImport from "../assets/svg/file-import.svg";



function Topbar(props) {
    const { t } = useTranslation();

    function exportData(e) {
        // props.setClicks(props.clicks+props.clickMultiplier);
        let savedData = localStorage.getItem("data");
        if (!savedData) {
            return false;
        }

        try {
            let test = JSON.parse(savedData)
            test.timestamp = Date.now();

            savedData = JSON.stringify(test)
        } catch (e) {
            console.log(e)
        }
        // prompt("Exported data:", base64_encode(savedData));
        prompt(t('Exported data'), base64_encode(savedData));
    }

    function importData(e) {
        // props.setClicks(props.clicks+props.clickMultiplier);

        props.setLoop(false);

        // let data = prompt("Import your data:")
        let data = prompt(t('Import your data'))
        if(!data) {
            props.setLoop(true);
            return false;
        }

        try {
            localStorage.setItem("data", base64_decode(data));
        } catch (e) {
            localStorage.setItem("data", null);
        }

        window.location.reload();
    }

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1100px)'})

    return (

        <div className={style.div}>
            <Logo text={"Marakuja FUN"}/>

            {/*<div>*/}
            {/*    <span>2</span>*/}
            {/*</div>*/}

            <div className={style.rightDiv} style={{display: "flex"}}>
                {isDesktopOrLaptop ? (
                    <>
                        <div onClick={importData} className={style.exportimportButton}>
                            {t('Import data')}
                        </div>

                        <div onClick={exportData} className={style.exportimportButton}>
                            {t('Export data')}
                        </div>
                    </>
                ) : (
                    <>
                        <div onClick={importData} className={style.exportimportButtonMOBILE} title={t('Import data')}>
                            <Icon icon={fileImport}/>
                        </div>

                        <div onClick={exportData} className={style.exportimportButtonMOBILE} title={t('Export data')}>
                            <Icon icon={fileExport}/>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Topbar;