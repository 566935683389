import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        fallbackLng: "en",
        returnObjects: true,
        interpolation: {
            escapeValue: false,
        },

        detection: {
            caches: ['localStorage'],
            lookupLocalStorage: 'Language',
            // lookupCookie: 'Language'
        },

        // supportedLngs: ['en', 'pl', 'es', 'de', 'ru', 'ig', 'ja', 'zu', 'zh-CN','zh-TW', 'zh', 'cs', 'hi', 'kk'],
        // supportedLngs: ['af', 'as', 'cs', 'csb', 'da', 'de', 'el', 'en', 'es','fr', 'hi', 'ig', 'it', 'ja','ka','kk','ne','nl','pl','ro',
        // 'ru','si','sq','sv','tr','xh','zh-CN','zh-TW','zh','zu'],
        supportedLngs: ['de', 'en', 'pl', 'ru', 'es'],
    });

export default i18n;