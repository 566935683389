import React, {useState} from 'react';

import ButtonIcon from './ButtonIcon';
import Select from './Select';
import style from '../assets/css/Footer.module.css';

import Trash from '../assets/svg/trash.svg';
import {useTranslation} from "react-i18next";

import Languages from './Languages';

function Footer(props) {
    const { t, i18n } = useTranslation();

    function removeData(e) {
        props.setLoop(false);

        let text = prompt(t("To reset your statistics, type \"Reset\""), "");

        try {
            window.umami.track('Remove data button')
        } catch (e ) {}


        if (text != null && text.toLowerCase() === t("Reset").toLowerCase()) {
            localStorage.removeItem("data");
            props.setAutoClickerCooldown(1)
            props.setAutoClicker(1);
            props.setClickMultiplier(1);
            props.setClicks(0);

            try {
                window.umami.track('Removed data')
            } catch (e ) {}
        }

        props.setLoop(true);
    }

    function findOptionByValue(value) {
        return Languages.find(option => option.value === value);
    }

    const [selectedOption, setSelectedOption] = useState(findOptionByValue(i18n.language));

    function changeLanguage(data) {
        try {
            window.umami.track('Change language button [' + data.value + "]")
        } catch (e ) {}

        i18n.changeLanguage(data.value).then(r => setSelectedOption(data));
    }

    return (
        <div className={style.div}>
            <Select defaultValue={selectedOption} onChange={changeLanguage}
                    options={Languages}/>

            {/*<div className={style.center}>*/}
            {/*    <a>Hey</a>*/}
            {/*</div>*/}

            <div className={style.right}>
                {props.clicks >= 1  && (
                    <ButtonIcon icon={Trash} alt={"Trash"} onClick={removeData}/>
                )}
            </div>
        </div>
    );
}

export default Footer;