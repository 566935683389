import React, {useState} from 'react';

import ButtonIcon from './../ButtonIcon';
import style from '../../assets/css/Footer.module.css';

import Rocket from '../../assets/svg/rocket-lunch.svg';
import filledRocket from '../../assets/svg/filled-rocket-lunch.svg';
import Trash from '../../assets/svg/trash.svg';
import {useTranslation} from "react-i18next";
import Select from "../Select";
import Languages from "../Languages";

function Footer(props) {
    const { t, i18n } = useTranslation();

    function handleClick(e) {
        props.setUpgradesVisible(!props.upgradesVisible);
    }

    function removeData(e) {
        let text = prompt(t("To reset your statistics, type \"Reset\""), "");
        try {
        window.umami.track('Remove data button')
        } catch (e ) {}

        if (text != null && text.toLowerCase() === t("Reset").toLowerCase()) {
            localStorage.removeItem("data");
            props.setAutoClickerCooldown(1)
            props.setAutoClicker(1);
            props.setClickMultiplier(1);
            props.setClicks(0);
            try {
            window.umami.track('Removed data')
            } catch (e ) {}
        }
    }

    function findOptionByValue(value) {
        return Languages.find(option => option.value === value);
    }

    const [selectedOption, setSelectedOption] = useState(findOptionByValue(i18n.language));

    function changeLanguage(data) {
        try {
        window.umami.track('Change language button [' + data.value + "]")
        } catch (e ) {}
        i18n.changeLanguage(data.value).then(r => setSelectedOption(data));
    }

    return (
        <div className={style.div}>
            {props.upgradesVisible && (
                <ButtonIcon icon={filledRocket} alt={"Filled rocket"} onClick={handleClick}/>
            )}

            {!props.upgradesVisible && (
                <ButtonIcon icon={Rocket} alt={"Rocket"} onClick={handleClick}/>
            )}


            <div className={style.right}>
                <Select defaultValue={selectedOption} onChange={changeLanguage}
                        options={Languages}/>

                {props.clicks >= 1  && (
                    <ButtonIcon icon={Trash} alt={"Trash"} onClick={removeData}/>
                )}
            </div>
        </div>
    );
}

export default Footer;